.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mt-auto {
  margin: 20px auto;
  display: block;
}

.btn {
  display: block;
  border-bottom: 2px solid #004068;
  color: #004068;
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: all .5s;
  margin: 0 70px;
}

.btn:hover {
  opacity: .7;
}

.celest-form-root {
  background-color: #fff;
  padding: 30px 20px 40px;
  border-radius: 8px;
  min-height: 70vh;
  border: 4px solid #004068;
}

.box-file-upload {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #878787;

  cursor: pointer;
  padding: 20px;
  display: block;
  border: 2px solid #282c34;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.box-file-upload span {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-weight: 500;
  color: #1A2027;
}

#fileToUpload {
  visibility: hidden;
}